<template>
  <div id="display">
    <div class="display_container_card">
      <div class="display_container_card__item title_container">
        <div class="title_container__item icon">
          <svg
            width="27"
            height="26"
            viewBox="0 0 27 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.4375 19.5V23.25H20.0625C20.3111 23.25 20.5496 23.3488 20.7254 23.5246C20.9012 23.7004 21 23.9389 21 24.1875C21 24.4361 20.9012 24.6746 20.7254 24.8504C20.5496 25.0262 20.3111 25.125 20.0625 25.125H6.9375C6.68886 25.125 6.4504 25.0262 6.27459 24.8504C6.09877 24.6746 6 24.4361 6 24.1875C6 23.9389 6.09877 23.7004 6.27459 23.5246C6.4504 23.3488 6.68886 23.25 6.9375 23.25H12.5625V19.5H4.125C3.13044 19.5 2.17661 19.1049 1.47335 18.4016C0.770088 17.6984 0.375 16.7446 0.375 15.75V4.5C0.375 3.50544 0.770088 2.55161 1.47335 1.84835C2.17661 1.14509 3.13044 0.75 4.125 0.75H22.875C23.8696 0.75 24.8234 1.14509 25.5266 1.84835C26.2299 2.55161 26.625 3.50544 26.625 4.5V15.75C26.625 16.7446 26.2299 17.6984 25.5266 18.4016C24.8234 19.1049 23.8696 19.5 22.875 19.5H14.4375ZM4.125 2.625C3.62772 2.625 3.15081 2.82254 2.79917 3.17417C2.44754 3.52581 2.25 4.00272 2.25 4.5V15.75C2.25 16.2473 2.44754 16.7242 2.79917 17.0758C3.15081 17.4275 3.62772 17.625 4.125 17.625H22.875C23.3723 17.625 23.8492 17.4275 24.2008 17.0758C24.5525 16.7242 24.75 16.2473 24.75 15.75V4.5C24.75 4.00272 24.5525 3.52581 24.2008 3.17417C23.8492 2.82254 23.3723 2.625 22.875 2.625H4.125Z"
              fill="#010101"
            />
          </svg>
        </div>
        <div v-if="title" :title="title" class="title_container__item title">
          <label>{{ title }}</label>
        </div>
      </div>
      <div class="display_container_card__item display_info">
        <info-container :data="info" />
      </div>
      <div class="display_container_card__item resolution_container">
        <div class="resolution_container__item title">
          <label>Resolutions</label>
        </div>
        <div class="resolution_container__item resolution_table_container">
          <div
            v-for="(col, i) in resolutions"
            :key="i"
            class="resolution_table_container__item column_container"
          >
            <div
              v-for="(el, iRow) in col"
              :key="iRow"
              class="column_container__item"
              :class="currentResolution === el ? 'selected' : ''"
            >
              <label>{{ el }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
export default {
  components: {
    "info-container": defineAsyncComponent(() =>
      import("@/components/InfoContainer.vue")
    ),
  },
  props: {
    title: {
      type: String,
      default() {
        return null;
      },
    },
    info: {
      type: Object,
      default() {
        return {};
      },
    },
    resolutions: {
      type: Array,
      default() {
        return [];
      },
    },
    currentResolution: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      slectedTab: "info",
    };
  },
};
</script>

<style lang="less">
.display_container_card {
  display: flex;
  flex-direction: column;
  width: 330px;
  height: 469px;
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
  padding: 30.5px 16.51px;
  margin-bottom: 25px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.title_container {
  display: flex;
  align-items: center;
  width: 100%;

  .title_container__item {
    &.title {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .icon {
    margin-right: 6.88px;
  }
}

.resolution_container {
  display: flex;
  flex-direction: column;

  .title {
    margin-top: 25px;
    margin-bottom: 15px;
    label {
      width: 80%;
    }
  }
}

.resolution_table_container {
  display: flex;
  justify-content: space-between;
  padding-right: 30px;
}

.column_container {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;


  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .column_container__item {
    margin-bottom: 5px;
    &.selected {
      color: #5bc367;
    }
  }
}
</style>
